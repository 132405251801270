import httpClient from '@/libs/http-client'

export function getBanner (params) {
  const url = '/api/etc/banner'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getPopup (params) {
  const url = '/api/etc/popupList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getPopupLogin (params) {
  const url = '/api/main/popupList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getHeadMsg (params) {
  const url = '/api/board/msgHeadList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMain (params) {
  const url = '/api/main'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getSiteOption (params) {
  const url = '/api/etc/siteOption'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getSiteCheckOption (params) {
  const url = '/api/common/siteCheckInfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainCashStatus (params) {
  const url = '/api/main/cashList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMainNotice (params) {
  const url = '/api/main/nt/list'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getJackpot (params) {
  const url = '/api/etc/jackpot'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
