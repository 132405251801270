<template>
  <transition name="fade">
    <signin v-if="isLoginOpen" @close="onCloseSignin" @signup="isLoginOpen = false;isSignupOpen = true"/>
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <transition name="fade">
    <join v-if="isSignupOpen" @close="onCloseSignup"/>
  </transition>

  <transition name="fade">
    <join-complete v-if="isSignupCompleteOpen" @close="isSignupCompleteOpen = false;isLoginOpen = true"/>
  </transition>

  <transition name="fade">
    <new-message v-if="isUnReadMessage" />
  </transition>

  <header v-if="gameCount" id="mainheader" :class="{ 'scrolled': isScrolled }">
    <div class="head_info">
      <div class="hinfo_wrap">
        <div class="logo">
          <a @click="goPageByName('main')">
            <img src="@/assets/img/logo.gif" />
          </a>
        </div>
        <ul class="header" :class="{'islogin' : userData}">
          <li>
            <div class="naviwrap" :class="{ 'notlogin' : !userData }">
              <ul class="navi" >
                <li class="dropdown" @mouseover="gnbMenuOpen('game')" v-if="gameCount['casino'] || gameCount['hc-casino']" >
                  <img src="../assets/img/buja/menu_card.svg">
                  <a class="dropdown-toggle">{{ $t('front.gnb.casino') }}</a>
                    <transition name="slide-fade">
                      <div class="dropdown-menu" v-if="isGameOpen" @mouseleave="isGameOpen = false">
                        <template v-if="gameCount['casino']">
                          <ul class="navitop">
                            <li>{{ $t('front.gnb.livecasino') }}</li>
                          </ul>
                          <ul class="navibottom">
                            <li>
                              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') == -1">
                                  <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                  <span class="dropname" v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</span>
                                </a>
                              </template>
                            </li>
                          </ul>
                        </template>

                        <template v-if="gameCount['hc-casino']">
                          <ul class="navitop pt15">
                            <li>{{ $t('front.gnb.hotelcasino') }}</li>
                          </ul>
                          <ul class="navibottom">
                            <li>
                              <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.code" >
                                <a @click="onCasinoSelectGame(item.groupCode, item.code)" v-if="item.groupCodeNameEN.indexOf('HC-') > -1">
                                  <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                  <span class="dropname" v-if="$i18n.locale !== 'ko'">{{item.codeNameEN}}</span>
                                </a>
                              </template>
                            </li>
                          </ul>
                        </template>
                      </div>
                    </transition>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('slot')" v-if="gameCount['slot'] || gameCount['h-slot']" >
                  <img src="../assets/img/buja/menu_slot.svg">
                  <a class="dropdown-toggle">{{ $t('front.gnb.slot') }}</a>
                  <transition name="slide-fade">
                    <div class="dropdown-menu" v-if="isSlotOpen" @mouseleave="isSlotOpen = false">
                      <template v-if="gameCount['slot']">
                        <ul class="navitop">
                          <li>{{ $t('front.gnb.slotgame') }}</li>
                        </ul>
                        <ul class="navibottom">
                          <li>
                            <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
                              <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"  v-if="item.groupCodeNameEN.indexOf('H-') == -1">
                                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                <span class="dropname" v-if="$i18n.locale !== 'ko'">{{item.codeNameEN}}</span>
                              </a>
                            </template>
                          </li>
                        </ul>
                      </template>

                      <template v-if="gameCount['h-slot']">
                        <ul class="navitop">
                          <li>{{ $t('front.gnb.hotelslot') }}</li>
                        </ul>
                        <ul class="navibottom">
                          <li>
                            <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.codeName">
                              <a @click="slotOpen('isSlot', 'slot', item.codeName, item.code)" v-if="item.groupCodeNameEN.indexOf('H-') > -1">
                                <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                                <span class="dropname" v-if="$i18n.locale !== 'ko'">{{item.codeNameEN}}</span>
                              </a>
                            </template>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </transition>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('sport')"  v-if="gameCount['sports']">
                  <a class="dropdown-toggle">{{ $t('front.gnb.sport') }}</a>
                  <transition name="slide-fade">
                    <div class="dropdown-menu" v-if="isSportsOpen" @mouseleave="isSportsOpen = false">
                      <ul class="navitop">
                        <li>{{ $t('front.gnb.sportgame') }}</li>
                      </ul>
                      <ul class="navibottom">
                        <li>
                          <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.code" >
                            <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                              <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                              <span class="dropname" v-if="$i18n.locale !== 'ko'">{{item.codeNameEN}}</span>
                            </a>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('minigame')" v-if="gameCount['mini-game']">
                  <a class="dropdown-toggle">{{ $t('front.gnb.minigame') }}</a>
                  <transition name="slide-fade">
                    <div class="dropdown-menu" v-if="isMiniOpen" @mouseleave="isMiniOpen = false">
                      <ul class="navitop">
                        <li>{{ $t('front.gnb.minigame') }}</li>
                      </ul>
                      <ul class="navibottom">
                        <li>
                          <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.code" >
                            <a @click="onCasinoSelectGame(item.groupCode, item.code)">
                              <span class="dropname" v-if="$i18n.locale === 'ko'">{{item.codeName}}</span>
                              <span class="dropname" v-if="$i18n.locale !== 'ko'">{{item.codeNameEN}}</span>
                            </a>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </li>
                <!-- <li class="dropdown" @mouseover="gnbMenuOpen('board')">
                  <a class="dropdown-toggle" @click="goPageByName('board')">{{ $t('front.common.board') }}</a>
                </li> -->
                <li class="dropdown" @mouseover="gnbMenuOpen('notice')">
                  <img src="../assets/img/buja/menu_noti.svg">
                  <a class="dropdown-toggle" @click="goPageByName('notice')">{{ $t('front.common.notice') }}</a>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('cs')">
                  <img src="../assets/img/buja/menu_cs.svg">
                  <a class="dropdown-toggle" @click="goPageByName('csCenter')">{{ $t('front.common.scscenter') }}</a>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('cash')">
                  <img src="../assets/img/buja/menu_cash.svg">
                  <a class="dropdown-toggle">{{ $t('front.common.depositWithdrawal') }}</a>
                    <transition name="fade">
                      <div class="dropdown-menu sdropmenu" v-if="isCashOpen" @mouseleave="isCashOpen = false">
                        <ul class="navibottom">
                          <li>
                            <a @click="goPageByName('charge')"><span class="dropname">{{ $t('front.common.deposit') }}</span></a>
                            <a @click="goPageByName('exchange')"><span class="dropname">{{ $t('front.common.withdrawal') }}</span></a>
                          </li>
                        </ul>
                      </div>
                    </transition>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('mypage')" v-if="userData">
                  <a class="dropdown-toggle" @click="goPageByName('mypage')">{{ $t('front.common.mypage') }}</a>
                </li>
                <li class="dropdown" @mouseover="gnbMenuOpen('partner')" v-if="userData">
                  <a class="dropdown-toggle" :href="'/SE6'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'">{{ $t('파트너페이지') }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="minfo" :class="{'islogin' : userData}">
          <ul class="coninpoint" v-if="userData">
            <li><span class="nick">{{userData.memNick}}</span></li>
            <li>
              <ul class="hd-left" v-if="userData">
                <li><img src="../assets/img/icon_cash.svg">캐시 : <span>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span>원</li>
                <li><img src="../assets/img/icon_point.svg">포인트 : <span>{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span>원</li>
                <li><img src="../assets/img/icon_msg.svg">메세지 : <span>{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span>건</li>
              </ul>
            </li>
          </ul>
          <template v-if="!userData">
            <div class="loginWrap">
              <button class="userUIBtn loginBtn" @click="onClickLogin"><img src="../assets/img/icon_login.svg">{{ $t('front.gnb.login') }}</button>
              <button class="userUIBtn joinBtn" @click="onClickSignup"><img src="../assets/img/icon_join.svg">{{ $t('front.common.signup') }}</button>
              <!-- <ui-button :className="'userUIBtn'" :text="$t('front.gnb.login')" @click="onClickLogin"/>
              <ui-button :className="'userUIBtn'" :text="$t('front.common.signup')" @click="onClickSignup"/> -->
            </div>
          </template>
          <template v-else>
            <ui-button :className="'userUIBtn'" :text="$t('front.common.logout')" @click="signOut"/>
          </template>
        </div>
          <!-- <div class="loginWrap" @mouseleave="showMenuLang = false">
            <div class="logindropdown" @mouseover="gnbMenuOpen('lang')">
              <button class="logindropbtn langbtn">
                <span class="langsp lang" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/korea.svg" /><span>KR</span></span>
                <span class="langsp lang" v-if="$i18n.locale === 'en'"><img src="@/assets/img/en.svg" /><span>EN</span></span>
                <span class="langsp lang" v-if="$i18n.locale === 'vn'"><img src="@/assets/img/vn.svg" /><span>VN</span></span>
              </button>
              <transition name="fade">
                <div class="logindropdown-content lang" v-if="showMenuLang">
                  <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.svg" /><span>KR</span></a>
                  <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/en.svg" /><span>EN</span></a>
                  <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /><span>VN</span></a>
                </div>
              </transition>
            </div>
          </div> -->
      </div>
    </div>
    <!--div class="topbanner">
      <div class="topbannerin">
         <div class="speakerIcon"><img src="@/assets/img/mc_con.png" /></div>
         <span class="">{{$t('front.header.msg')}}</span>
      </div>
    </div-->
    <!-- <div class="head_menu">
    </div> -->
  </header>
</template>

<script>

import UiButton from '@/components/ui/UiButton'

import { mapState } from 'vuex'
import Join from '@/components/member/Join'
import JoinComplete from '@/components/member/JoinComplete'
import Signin from '@/components/member/Signin'
import TMmodal from '@/components/common/TotalMoney'
import { TRIPLE_GAME_LIST } from '@/libs/constants'
import NewMessage from '@/components/member/Message.vue'

export default {
  name: 'Header',
  components: {
    NewMessage,
    Signin,
    JoinComplete,
    Join,
    UiButton,
    TMmodal
  },
  data () {
    return {
      menuList: [],
      TMOpen: false,
      isLoginOpen: false,
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      showMenu: false,
      showMenuMoney: false,
      showMenuLang: false,
      isAlertOpen: false,
      isGameOpen: false,
      isSlotOpen: false,
      isSportsOpen: false,
      isMiniOpen: false,
      isCashOpen: false,
      isScrolled: false,
      tripleGameList: TRIPLE_GAME_LIST
    }
  },
  watch: {
    $route: {
      handler (current) {
        console.log('$route name', this.$route)
        this.gnbReset()
      }
    }
  },
  created () {
    this.setMenu()
    this.getHeadMsgList()
  },
  computed: {
    ...mapState([
      'userData',
      'coinAmt',
      'commonCodeByOrder',
      'gameCount',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.isScrolled = window.scrollY > 100
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
    },
    gnbReset () {
      this.isGameOpen = false
      this.isSlotOpen = false
      this.isSportsOpen = false
      this.isMiniOpen = false
      this.isCashOpen = false
      this.showMenu = false
      this.showMenuLang = false
    },
    gnbMenuOpen (type) {
      this.gnbReset()
      if (type === 'game') {
        this.isGameOpen = true
      } else if (type === 'slot') {
        this.isSlotOpen = true
      } else if (type === 'cash') {
        this.isCashOpen = true
      } else if (type === 'mypage') {
        this.showMenu = true
      } else if (type === 'lang') {
        this.showMenuLang = true
      } else if (type === 'sport') {
        this.isSportsOpen = true
      } else if (type === 'minigame') {
        this.isMiniOpen = true
      }
    },
    getHeadMsgList () {
      // getHeadMsg({}).then(response => {
      //   console.log(response)
      // })
    },
    setMenu () {
      const list = this.$router.getRoutes()
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.meta && item.meta.gnb) {
          item.isSubOpen = false
          this.menuList.push(item)
        }
      }
    },
    onClickGame (code, name) {
      this.gnbReset()
      if (code) {
        // minigame

        if (code === 'mg') {
          this.$router.push({ name: name })
        } else {
          this.onSelectGame(code)
        }
      } else {
        this.onCheck('준비 중입니다.')
      }
    },
    onClickGnb (item) {
      const name = item.name
      const meta = item.meta
      if (meta.game) {
        this.onSelectGame(meta.gameCode)
      } else {
        this.$router.push({ name: name })
      }
    },
    async onClickTM () {
      const confirmMessage = '환전신청을 하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    onClickLogin () {
      this.isLoginOpen = true
      this.isSignupOpen = false
    },
    onClickSignup () {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignin (value) {
      this.isLoginOpen = false
      if (value === 'join') {
        this.isSignupOpen = true
      }
      if (value === 'signin') {
        // location.reload()
      }
    },
    onCloseSignup (status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    }
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.scrolled{
  position: fixed;
  top: 0;
}
</style>
