<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <!-- <ul class="calogo">
        <li><img src="@/assets/img/ca_logo1.png" /></li>
        <li><img src="@/assets/img/ca_logo2.png" /></li>
        <li><img src="@/assets/img/ca_logo3.png" /></li>
        <li><img src="@/assets/img/ca_logo4.png" /></li>
        <li><img src="@/assets/img/ca_logo5.png" /></li>
        <li><img src="@/assets/img/ca_logo6.png" /></li>
        <li><img src="@/assets/img/ca_logo7.png" /></li>
      </ul> -->
      <div class="nav-bottom">
        <div class="footerInfo">
          <div class="footerl">
             <ul class="footer_icon">
                <li><img src="@/assets/img/footer/footer_icon1.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon2.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon3.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon4.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon5.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon6.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon7.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon8.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon9.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon10.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon11.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon12.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon13.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon14.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon15.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon16.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon17.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon18.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon19.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon20.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon21.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon22.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon23.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon24.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon25.png" /></li>
                <li><img src="@/assets/img/footer/footer_icon26.png" /></li>
             </ul>
          </div>
          <div class="footerr">
            <a class="flogo" @click="goPageByName('main')">
              <!-- <img src="@/assets/img/peanutlogo.svg" /> -->
              <span class="copyright">ONLINE CUSTOMER SUPPORT CONTACT BY TELEGRAM : @<br />COPYRIGHT ⓒ 2024 BUJA CASINO. ALL RIGHTS RESERVED.</span>
            </a>
            <!--ul class="footerNav">
              <li @click="goPageByName('qnaWrite')">{{ $t('front.common.qna')}}</li>
              <li @click="goPageByName('notice')">{{ $t('front.common.notice')}}</li>
              <li @click="goPageByName('faq')">{{ $t('front.common.faq')}}</li>
              <li @click="goPageByName('bettingGuide')">{{ $t('front.common.bettingGuide')}}</li>
              <li @click="goPageByName('result')">{{ $t('front.common.gameResult')}}</li>
              <li @click="goPageByName('boardList')">{{ $t('front.common.board')}}</li>
            </ul>
            <ul class="sns" v-if="siteOption">
              <li @click="siteOption['sns2Link']">
                <img src="@/assets/img/telegram.svg" />
                <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
              </li>
              <li @click="siteOption['sns1Link']">
                <img src="@/assets/img/kakao.svg" />
                <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
              </li>
              <li v-if="siteOption['sns3']" @click="siteOption['sns3Link']">
                <img src="@/assets/img/zalo.svg" />
                <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
              </li>
            </ul-->
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
